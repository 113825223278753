.user_mode #siteContainer {
    //max-width: 100%;
    padding: 0;

    .link-container {
        background-color: rgba(0,0,0,.4);
        margin-top: 244px;

        .linkElement h4 a {
            font-family: graduate, sans-serif;
            font-size: 24px;
            font-weight: 400;
            text-shadow: 3px 3px 1px #000;
            text-align: center;
            background: none;
            box-shadow: none;
            transition: color .5s, background-color .5s;
            border: 1px solid #e8d69f;

            &:after {
                display: none;
            }
        }
    }
}

#siteContainer .yieldPageContent {
    padding-top: 15px;
}

.user_mode .splash-photos {
    margin: 0 auto;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    background-repeat: no-repeat;
    background-position: center bottom;
    margin-bottom: 50px;

    .column {
        max-width: 470px;
        margin-bottom: 20px;
    }
}

.user_mode .splash-photo {
    .heroPhotoElement {
        font-family: $splashFontFamily;
        padding: 0;
        margin: 0;
        position: relative;
        z-index: 100;

        img {
            transition: filter .5s;
            position: relative;
            z-index: 0;
        }

        a:hover {
            text-decoration: none;
            color: #057509;

            p {
                color: #057509;
            }

            img {
                filter: drop-shadow(0 0 15px white)
            }
        }

        .cutline,
        img {
            border: none;
            padding: 0 40px;
        }

        .cutline {
            font-weight: 700;
            font-size: 1.3em;
            line-height: 41px;
            font-style: normal;
            text-align: center;
            letter-spacing: 4px;
            height: 41px;
            color: #fff;
            background: none;
            background-position: center top;
            background-size: 100% 41px;
            background-repeat: no-repeat;
            position: relative;
            z-index: 200;
            top: -8px;
            padding-top: 20px;
        }
    }
}

.user_mode .splash-bar {
    // position: relative;
    // top: -57px;
    // z-index: 0;
    display: none;
}
// Tablet
@media only screen and (min-width: 768px) {
    .site-background {
        background-size: contain;
    }

    .user_mode #siteContainer {
        .link-container {
            background-color: rgba(0,0,0,.4);
            margin-top: 350px;
            padding: 0 15%;

            .linkElement h4 a {
                border: none;
            }
        }
    }

    footer.snFooterContainer {
        margin-top: 75px;
    }
}
// Desktop
@media only screen and (min-width: 1024px) {
    .site-background {
        background-size: 75% auto;
    }

    .user_mode #siteContainer {
        max-width: 100%;

        .link-container {
            margin-top: 436px;
            padding: 0 20%;

            .linkElement h4 a {
                font-size: 30px;
            }
        }
    }

    footer.snFooterContainer {
        margin-top: 130px;
    }
}

// This file resets the layout so it's ready for use as a splash page
// - Hides necessary items
// - Adjusts positoning due to hiding certain elements
//
// Works best when the splash can be formatted with an Admin theme background
// and the interactive items can be managed using photo/link elements
//

html.collapsed-mobile-nav .site-background {
    margin-top: 0;
}

html:not(.collapsed-mobile-nav):not(.has-sub-nav) .site-background {
    margin-top: 0;
}

.user_mode .site-banner-wrapper {
    background-position: center -15px;
    background-size: 150% auto;
    background-repeat: no-repeat;
    height: 125px;
}

.site-background {
    background-size: 250% auto;
    background-position: center -50px;
    min-height: 100vh;
}

#siteContainer {
    min-height: auto;
    padding-top: 0;
}

.heroPhotoElement img {
    border: none;
}

footer.snFooterContainer {
    //background: linear-gradient(to bottom, rgba(0,0,0,0) 0, rgba(0,0,0,.6) 10px, rgba(0,0,0,.8) 100%);
    //margin-top: 100px;
    max-width: 100%;
}

.user_mode {
    #displayBodyHeader,
    #topNav,
    #topNavPlaceholder {
        display: none;
    }
}

.has-mobile-nav body.user_mode:before {
    display: none;
}

body {
    background-color: $background-color;
}

#siteContainer,
.snFooterContainer {
    background: none;
}

#siteFooter {
    & > ul {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;

        li {
            text-align: center;

            &#poweredByNGIN a {
                width: 100%;
                margin: 0 auto;

                &:after {
                    content: url("http://app-assets1.sportngin.com/logo_images/logo.svg");
                    width: 50%;
                    margin: 0 auto;
                    position: relative;
                }
            }

            &:first-child {
                display: block;
                text-align: center;
            }

            &:nth-last-child(2) {
                width: 100%;
            }
        }

        a#loginLink {
            //background-color: #105f7f;
            color: #fff;
            display: block;
            width: 100%;
            padding: 10px;
            margin: 10px auto;
            text-transform: uppercase;
        }
    }
}
@media only screen and (min-width: 768px) {
    .user_mode .site-banner-wrapper {
        background-position: center -15px;
        background-size: 100% auto;
        height: auto;
    }

    .site-background {
        background-size: 150% auto;
    }

    #siteFooter {
        & > ul {
            // display: block;
            // text-align: center;
            li {
                // text-align: left;
                &#poweredByNGIN a {
                    // width: auto;
                    // max-width: 200px;
                    // margin: 0 auto;
                    &:after {
                        // content: url("http://app-assets1.sportngin.com/logo_images/logo.svg");
                        width: 150px;
                        // margin: 0 auto;
                        // position: relative;
                    }
                }

                &:first-child {
                    // display: block;
                    // text-align: center;
                }

                &:nth-last-child(2) {
                    width: auto;
                }
            }

            a#loginLink {
                background: none;
                // color: #999;
                display: inline;
                width: auto;
                padding: 0;
                margin: 0;
                text-transform: none;
            }
        }
    }
}
@media only screen and (min-width: 1024px) {
    .site-background {
        background-size: initial;
    }

    #siteFooter {
        & > ul {
            display: block;
            text-align: center;

            li {
                text-align: left;

                &#poweredByNGIN a {
                    display: inline-block;

                    &:after {
                        width: 120px;
                        top: 8px;
                    }
                }

                &:first-child {
                    display: inline-block;
                    text-align: left;
                }
            }

            a#loginLink {}
        }
    }

    body.noUserBar {
        padding-top: 44px;
    }

    html.has-main-nav:not(.has-sub-nav) #siteHeader {
        margin-top: 0;
    }
}
